import React from 'react';
import { graphql } from 'gatsby';
import MainLayout from 'src/layout/MainLayout';
import {
  Grid,
  SectionHead,
  List,
  ContentBox,
  Paragraphs,
  HalvedContent
} from 'src/components';
import { Card, CardHeader } from 'src/components/Cards';
import content from 'src/content/unsere-loesungen/absicherung-fuer-mitarbeiter/betriebliche-altersvorsorge/bav-pensionsfonds';
import subpageNavigations from 'src/data/subpage-navigations';
import BackgroundImage from 'gatsby-background-image';

const BAVPensionsfondsPage = ({ data }) => (
  <MainLayout
    title={content.title}
    headvisual={{
      image: data.headvisual,
      position: '75',
      headline: content.headvisualHeadline
    }}
    subpageNavItems={subpageNavigations.betrieblicheAltersvorsorge}
    activeNavItem={4}
    useParentPathname
  >
    <section>
      <Grid>
        <HalvedContent swapped breakpoint="xl">
          <BackgroundImage
            fluid={data.smilingManWithBlueJacket.childImageSharp.fluid}
          />
          <ContentBox
            background={content.uebersichtSection.background}
            topline={content.uebersichtSection.topline}
            headline={content.uebersichtSection.headline}
            align="right"
          />
        </HalvedContent>

        <div className="children-margin">
          <Paragraphs items={content.uebersichtSection.paragraphs} />
        </div>
      </Grid>
    </section>
    <section className="bg-light-blue">
      <SectionHead headline={content.strategienSection.headline} centered>
        <p>{content.strategienSection.content}</p>
      </SectionHead>
      <Grid>
        {content.strategienSection.cards.map((card, index) => (
          <Card key={index}>
            <CardHeader headline={card.headline} />
            <Paragraphs items={card.paragraphs} />
            <h4>{card.subheadline}</h4>
            <List items={card.items}></List>
          </Card>
        ))}
      </Grid>
    </section>
    <section>
      <Grid centerItems>
        <div className="children-margin">
          <h3>{content.beratungsSection.headline}</h3>
          <p>{content.beratungsSection.content}</p>
        </div>
        <div className="children-margin">
          {content.beratungsSection.cards.map((card, index) => (
            <Card key={index}>
              <CardHeader headline={card.headline} parallel>
                <card.icon />
              </CardHeader>
              <p>{card.content}</p>
            </Card>
          ))}
        </div>
      </Grid>
    </section>
    <section>
      <ContentBox
        background={content.vorteileSection.background}
        headline={content.vorteileSection.headline}
        single
      >
        <List items={content.vorteileSection.items} columns={2} />
      </ContentBox>
    </section>
  </MainLayout>
);

export default BAVPensionsfondsPage;

export const query = graphql`
  {
    headvisual: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "woman-smiles-and-wearing-blue-jacket.jpg" }
    ) {
      ...image
    }
    smilingManWithBlueJacket: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "men-wearing-blue-jacket-and-smiling.jpg" }
    ) {
      ...image
    }
  }
`;
