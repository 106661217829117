import HeadIcon from 'src/assets/icons/brainstorm-skill.svg';

const content = {
  title: 'bAV Pensionsfonds',
  headvisualHeadline: 'Betriebliche Altersvorsorge (bAV) - Pensionsfonds',
  uebersichtSection: {
    background: 'gray-blue',
    topline: 'Betriebliche Altersvorsorge',
    headline: 'Pensionsfonds - Einfach gut für Ihre Bilanz',
    paragraphs: [
      'Die hohen Verpflichtungswerte aus Pensionszusagen und deren häufig völlig unzureichende Ausfinanzierung bergen für Unternehmen unkalkulierbare betriebswirtschaftliche Risiken. Insbesondere bei der Unternehmensübergabe, dem Unternehmensverkauf, im internationalen Geschäftsverkehr oder bei der Unternehmensliquidation stellen Pensionszusagen deshalb ein oft unüberwindbares Hemmnis dar.',
      'Deswegen sollte schon frühzeitig an die Sanierung bzw. Auslagerung der Pensionszusage gedacht werden.',
      'Aus diesem Grund bietet die NÜRNBERGER Pensionsfonds AG verschiedene interessante und hilfreiche Optionen für Sie, um Ihre die Bilanz zukunftssicher zu gestalten.'
    ]
  },
  strategienSection: {
    headline: '2 Strategien zur Auswahl - So wie es für sie passt.',
    content:
      'Mithilfe eines Pensionsfonds können Versorgungsverpflichtungen der betrieblichen Altersversorgung ausgelagert und somit gesichert werden. Hierfür gibt es verschiedene Kapitalanlagestrategien:',
    cards: [
      {
        headline: 'Anlage mit fest definierten Anlageklassen',
        paragraphs: [
          'Diese Variante ist für Anleger geeignet, die die Risiken der Kapitalanlage über die Vertragsdauer hinweg anhand der Anlage mit fest definierten Anlageklassen und vereinbarter Mini- bzw. Maximalhöhe festlegen wollen.',
          'Eine Umschichtung seitens des Pensionsfonds ist nur innerhalb der fest vereinbarten Assetklassen und der vereinbarten Grenzwerte möglich. Somit kann insbesondere der Höchstanteil der Aktien durch die Wahl der Anlagestrategie gesteuert werden.'
        ],
        subheadline: 'Strategien',
        items: ['Sicherheit', 'Rendite', 'Rendite Plus']
      },
      {
        headline: 'Zielrenditesystem',
        paragraphs: [
          'Diese Variante ist vor allem für Anleger geeignet, deren Fokus vorrangig auf dem Erreichen der angestrebten Zielrendite liegt. Dabei ist der Pensionsfonds in der Anlage der Gelder frei.',
          'Abhängig von der Kapitalmarktlage erfolgen regelmäßig Umschichtungen der Anlageklassen mit dem Ziel, die anvisierte Zielrendite möglichst zu erreichen.',
          'Insbesondere der Anteil der Aktien und aktienähnlichen Anlageklassen kann dadurch während der Vertragslaufzeit stark variieren.'
        ],
        subheadline: 'Strategien',
        items: ['smart2', 'smart3', 'smart4']
      }
    ]
  },
  beratungsSection: {
    headline: 'Fachkundig beraten.',
    content:
      'Die Experten des NÜRNBERER Pensionsfonds finden auch für Sie eine maßgeschneiderte Lösung um Ihr Unternehmen fit für die Zukunft zu machen.',
    cards: [
      {
        icon: HeadIcon,
        headline: 'Erfahrung mit Experten',
        content:
          'Hinter jeder Beratung durch unsere Spezialisten steht ein starkes Team mit fachlich fundiertem Wissen, das immer kundenorientiert daran interessiert ist das bestmögliche Paket für Sie zusammenzustellen.'
      },
      {
        icon: HeadIcon,
        headline: 'Individuelle Lösungen',
        content:
          'Jede Pensionszusage ist anders. Deswegen suchen wir für Sie die optimale Lösung die genau zu Ihrer Situation passt. Ob Renten- oder Kapitalzusage: Beim NÜRNBERGER Pensionsfonds ist beides möglich! Und wenn der Einmalbeitrag zu teuer sein sollte geht es auch per Ratenzahlung.'
      }
    ]
  },
  vorteileSection: {
    headline: 'Ihre Vorteile auf einen Blick',
    background: 'light-blue',
    items: [
      'Mit dem Zielrenditesystem das Nachfinanzierungsrisiko reduzieren',
      'Rückdeckungsversicherungen mit einbeziehen, statt kündigen',
      'Mit einem Biometriepuffer Ruhe in die Auslagerung bringen',
      'Mit der NÜRNBERGER und Fidelity zwei erfahrene Partner an der Seite haben',
      'Nahezu jede Pensionszusage abbildbar, auch Kapitalzusagen',
      'Kompetente Betreuung durch unsere Spezialisten'
    ]
  }
};

export default content;
